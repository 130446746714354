import axios from "axios";

export default {
  methods: {

    get_mois_maj_acv() {
      return axios
        .get('/conf/maj_ACV.conf')
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            return error.response.data;
          }
        );
    },

    calculate_carbon_emissions() {
      return axios
        .post(process.env.VUE_APP_CLOUD_FUNCTION_URL)
        .then(
          (response) => {
            return response.data.data;
          },
          (error) => {
            return error.response.data;
          }
        );
    },
  },
};
